import React, { useState, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import { Layout, Seo, Container, RoundedBox } from "../../components"
import { ArrowRight } from "../../assets/vectors"
import * as styles from './style.module.scss'
import { arrayOf } from "prop-types"
import { Arrow } from "../../components/LanguageModal/styles"


const HowToPlayTemplate = ({ pageContext, data }) => {

	const options = data.howtos.edges && data.howtos.edges.map((edge =>
		edge.node.howToFields.howtoFields.find((variant =>
			variant.languageValue.slug === pageContext.lang)
		).section.name
	)).filter((name, i, array) => array.indexOf(name) === i)

	const [ accordeon, setAccordeon ] = useState(false)
	const [ categories, setCategories ] = useState(options)
	const [ tutorialSelected, setTutorialSelected ] = useState(categories[0])

	const URL_PREFIX = pageContext.lang === "en_us" ? "" : "/pt"

	const tutorials = useRef()
	const menu = useRef()

	const { seoMetaFields, howToPlayPageFields } = data.page
	const { edges } = data.howtos

	function translate(property, field) {
		const data = property[field].find(
			variant => variant.languageValue.slug === pageContext.lang
		)

		return data
	}

	const selectTutorial = (e) => {
		e.preventDefault()

		const anchors = menu.current.querySelectorAll('a');
		[...anchors].map(anchor => anchor.classList.remove(styles.active))

		e.target.classList.add(styles.active)

		const elements = tutorials.current.querySelectorAll('a')
		elements.forEach(element => element.style.display = "none")

		const active = tutorials.current.querySelectorAll(`[data-category="${e.target.getAttribute('data-cat')}"]`)
		active.forEach(element => element.style.display = "initial")

		// setTutorialSelected(e.target.getAttribute('data-cat'))
		setAccordeon(false)
	}

	let arr = []

  return (
    <Layout>
      <Seo title={translate(seoMetaFields, 'wpFields').seoTitle} description={translate(seoMetaFields, 'wpFields').seoDescription} />

			<RoundedBox
				className={styles.roundedBox}
				transform="rotate(26deg)"
				width="916px"
				height="996px"
				position={{
					top: "-800px",
					right: "100px"
				}}
			/>

      <section className={styles.howTo}>
				<Container>
					<header className={`${styles.howTo__header} flex align-center`}>
						<h1 className={styles.pageTitle}>
							{translate(howToPlayPageFields, 'topSection').title}
						</h1>
						<span dangerouslySetInnerHTML={{__html: translate(howToPlayPageFields, 'topSection').copy}}></span>
					</header>

					<div className={`${styles.howTo__body} flex`}>
						<aside className={styles.menu__wrapper}>
							<div className={styles.accordeonValue} onClick={() => setAccordeon(!accordeon)}>
								<span>{tutorialSelected}</span>
								<span className={styles.triangleDown}></span>
							</div>

							{
								categories && categories.length > 0 &&
								<ul className={`${styles.menu} ${accordeon ? styles.menu__mobileActive : ''}`} ref={menu}>
									{
										categories.map((cat, index) =>
											<li><a href="#" data-cat={cat} className={`${index === 0 ? styles.active : ''}`} onClick={selectTutorial}>{cat}</a></li>
										)
									}
								</ul>
							}
						</aside>
						<div className={styles.content} ref={tutorials}>

							{
								edges &&
								edges.map((tutorial, index) => {
									const info = translate(tutorial.node.howToFields, 'howtoFields')
									const section = info.section
									if (categories.indexOf(section.name) === -1) {
										arr.push(section.name);
										setCategories(arr)
									}

									let comparator = 0;

									if ( index > 1 && index < 4 ) {
										comparator = 3;
									} else {
										comparator = 2;
									}

									let isEven = index % comparator == 0;

									return (
										<Link
											key={Math.random()}
											to={`${URL_PREFIX}/${info.seoRoot}/${info.seoSlug}`}
											className={styles.card__wrapper}
											data-category={section.name}
											style={{
												display: categories[0] === section.name ? 'initial' : 'none'
											}}
										>
											<div className={`${styles.card} card card__default ${isEven ? "card--purple" : "card--yellow" }`}>
												<img className={styles.image} src={info.tabs[0].image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
											</div>

											<p>{info.tabs[0].title} <ArrowRight /></p>
										</Link>
									)
								})
							}
						</div>
					</div>
				</Container>
			</section>
    </Layout>
  )
}

export const Query = graphql`query HowToPlayTemplate {
  page: wpPage(slug: {eq: "how-to-play"}) {
    id
    seoMetaFields {
      wpFields {
        languageValue {
          slug
          name
        }
        seoTitle
        seoDescription
      }
    }
    howToPlayPageFields {
      topSection {
        languageValue {
          slug
          name
        }
        title
        copy
      }
    }
  }
  howtos: allWpHowTo {
    edges {
      node {
        id
        slug
        howToFields {
          howtoFields {
            languageValue {
              name
              slug
            }
						section {
	            databaseId
	            name
							slug
	          }
						seoRoot
						seoSlug
            tabs {
              title
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default HowToPlayTemplate
