// extracted by mini-css-extract-plugin
export var howTo = "style-module--how-to--1AMOw";
export var howTo__header = "style-module--how-to__header--3ixnT";
export var pageTitle = "style-module--page-title--_2x5U";
export var howTo__body = "style-module--how-to__body--386N4";
export var menu__wrapper = "style-module--menu__wrapper--1eYJ2";
export var accordeonValue = "style-module--accordeonValue--1sHeC";
export var triangleDown = "style-module--triangle-down--2fATB";
export var menu = "style-module--menu--12lxV";
export var active = "style-module--active--3QPbV";
export var content = "style-module--content--Epve1";
export var card__wrapper = "style-module--card__wrapper--2Qpe9";
export var card = "style-module--card--33aiA";
export var image = "style-module--image--3Z9-v";
export var menu__mobileActive = "style-module--menu__mobile--active--3Hil5";
export var roundedBox = "style-module--roundedBox--3StIu";